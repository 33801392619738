import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => {
    return (
        <Layout lang={'en'}>
            <SEO title="404: Not Found" />
            <div
                style={{
                    display: 'flex',
                    minHeight: '100vh',
                    flexDirection: 'column',
                    justifyContent: 'flex-start'
                }}
            >
                <h1>Page not found / Página no encontrada</h1>
                <p>
          Oops! The page you are looking for has been removed or relocated /
          Oops! La página que estas buscando ha sido removida o reubicada.
                </p>
                <Link to={'/'}>Go home</Link>
                <Link to={'/es/'}>Ir al inicio</Link>
            </div>
        </Layout>
    )
}

export default NotFoundPage
